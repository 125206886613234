export const firstTimeLoading = () => {
  const loadingElement = document.getElementById('loading')
  const rootElement = document.getElementById('root')

  if (loadingElement) {
    loadingElement?.classList.add('lds-ripple-display-hide')

    setTimeout(() => {
      loadingElement.remove()
    }, 2000)
  }
  if (rootElement) {
    setTimeout(() => {
      rootElement.classList.add('root-show')
    }, 200)
  }
}
