import useSignalR, { SignalREventConfig } from '@hooks/useSignalR'
import { SIGNALR_EVENT } from '@constants/index'
import useTextResource from '@infotrack/text-resource'

interface IHookReturn {
  init: ({ token, url, Illustration }: { token: string; url: string; Illustration: any }) => void
}

export const useMarkdownText = (): IHookReturn => {
  const { init, loading, fetchMarkdownText } = useTextResource()
  const eventConfigs: SignalREventConfig[] = [
    {
      type: 'on',
      eventName: {
        name: SIGNALR_EVENT.TEXT_RESOURCES_UPDATED
      },
      callback: () => {
        !loading && fetchMarkdownText()
      }
    }
  ]
  useSignalR(eventConfigs)

  return { init }
}
