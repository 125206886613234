export const PAGE_SIZE_DEFAULT = 10
// Max size to upload: 5MB
export const MAX_SIZE_UPLOAD_FILE = 5242880
export const SITE_KEY_CAPTCHA = process.env.REACT_APP_SITE_KEY_CAPTCHA ?? '' //see https://www.google.com/recaptcha/admin#createsite
export const REG_EX_PATTERN = {
  PASSWORD_VALID: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
  /**
   * This regex will enforce these rules:
    At least one upper case English letter, (?=.*?[A-Z])
    At least one lower case English letter, (?=.*?[a-z])
    At least one digit, (?=.*?[0-9])
    At least one special character, (?=.*?[#?!@$%^&*-])
    Minimum eight in length .{8,} (with the anchors)

   */
  EMAIL_VALID:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  /**
   * This regex will enforce these rules:
   * will match a string that contains exactly 11 character (either uppercase or lowercase) and number
   * */
  ABN_VALID: /^[0-9]{11}$/,
  /**
   * This regex will enforce these rules:
   * will match a string that contains 10-15 number
   * */
  ONLY_NUMBER_TEN_TO_FIFTHTEEN: /^[0-9]{1,10}$/,
  /*
   * Accept 4 digits from 0000 to 9999
   */
  POST_CODE_VALID: /^[0-9]{4}$/,
  /*
   * Validates date strings in the format "dd/mm/yyyy"
   */
  DATE_FORMAT_VALID: /^(0[1-9]|1\d|2\d|3[01]) \/ (0[1-9]|1[0-2]) \/ \d{4}$/
}

export const MINIMUM_WORD = 2
export const MAX_LENGTH_POSTCODE = 4
export const MAXIMUM_CHARACTER = 50
export const EXTENSION_PDF = 'application/pdf'
export const EXTENSION_DOC = 'application/msword'
export const EXTENSION_DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const EXTENSION_CSV = 'text/csv'
export const EXTENSION_CSV_LEAP = 'application/vnd.ms-excel'
export const API_ERROR_MESSAGE = {
  401: 'Token Validation Has Failed. Request Access Denied',
  REACTIVATE_LINK_OVER_5_TIMES: 'Exceeded maximum regeneration time (5 times).',
  INVALID_URL_VALIDATION: 'Firm has updated their email. Please contact help desk for more details.'
}

export const MINIMUM_PHONE_NUMBER_LENGTH = 5
export const MAXIMUM_ADD = 5
export const APP_URL = {
  BASE: '/',
  NOTICES: '/notices',
  SETTINGS: '/settings',
  FIND_WILLS: '/find-wills',
  ON_BOARDING: '/onboarding',
  PROBATE_NOTICES: '/probate-notices',
  ORDER_DISPLAY: '/order-display',
  UNDER_REVIEW: '/under-review',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  SET_PASSWORD: '/set-password',
  REGISTER: '/register',
  SETUP_SETTING: '/setup-setting',
  SOCIETY_WILL_LOCATION: '/society-will-location'
}

export const ICON = {
  ALERT: 'alert',
  ERROR: 'error',
  SUCCESS: 'success'
}

export const MAX_LENGTH_TEXT_250 = {
  message: 'The maximum number of characters is 250',
  value: 250
}

export const PERMISSION_DENIED_MESSAGE = `You don't have permissions to do this action. Please contact help desk for support.`
export const ERROR_SEARCH_INPUT_TOO_SHORT = 'Search value must be at least 2 characters'
export const EXISTS_EMAIL = 'Email already exists'
export const MIN_SEARCH_LENGTH = 2
export const KEY_NAME = {
  ENTER: 'Enter'
}
export const PRICE_SEARCH = 19
export const MAX_PUBLISHER_SOURCES = 5
export const NOTICES_STATUS = {
  NEW_NOTICE: 'NEW NOTICE',
  DISMISSED: 'DISMISSED',
  NEW_REQUEST: 'New Request'
}

export const SIGNALR_EVENT = {
  TEXT_RESOURCES_UPDATED: 'TextResourcesUpdated',
  ADD_TO_ORDER_GROUP: 'AddToOrderGroup',
  REMOVE_FROM_ORDER_GROUP: 'RemoveFromOrderGroup',
  ORDER_UPDATED: 'OrderUpdated'
}

//define enum
export enum ReleaseFlags {
  MISSING_WILL = 1,
  CHARGING_MODEL = 2
}

export enum Permission {
  None = 0,
  ViewNotice = 1 << 0,
  AddNotice = 1 << 1,
  EditNotice = 1 << 2,
  DeleteNotice = 1 << 3,
  ViewWill = 1 << 4,
  AddWill = 1 << 5,
  EditWill = 1 << 6,
  DeleteWill = 1 << 7,
  ViewUser = 1 << 8,
  AddUser = 1 << 9,
  EditUser = 1 << 10,
  DeleteUser = 1 << 11,
  ViewTenant = 1 << 12,
  AddTenant = 1 << 13,
  EditTenant = 1 << 14,
  DeleteTenant = 1 << 15,
  ActionWillNoticeMatch = 1 << 16,
  ViewClient = 1 << 17,
  AddClient = 1 << 18,
  EditClient = 1 << 19,
  DeleteClient = 1 << 20,
  ActionClientNoticeMatch = 1 << 21,
  ManageNoticeIntegration = 1 << 22,
  ViewNoticeDashboard = 1 << 23,
  ViewSyncWillDashboard = 1 << 24
}

export enum PmsType {
  NO_INTEGRATION,
  ONE_PRATICE,
  JUNIOR_PARTNER,
  LEGAL_OFFICE,
  INFINITY_LAW
}

export enum SlideId {
  INTEGRATION = 1,
  INTEGRATIONDOWNLOADKEY = 2,
  NOTIFICATION = 3
}

export enum ActionType {
  SEARCHWILL = 1,
  SEARCHDEATHNOTICE = 2,
  SEARCHPROBATENOTICE = 3
}

export enum MissingWillSearchType {
  SYSTEM_SEARCH,
  SOCIETY_SEARCH,
  TRUSTEE_SEARCH,
  ADVERTISEMENT_SEARCH,
  LAW_SOCIEY_BLOG_SEARCH,
  PROPERTY_OWNERSHIP_SEARCH
}

export enum Phares {
  INTERNAL_LAW_FIRM = 6,
  SOCIETY_LAW_FIRM = 7,
  TRUSTEE = 8,
  ADVERTISEMENT = 9,
  PROPERTY_OWNERSHIP = 10,
  LAW_SOCIETY_BLOG = 11
}

export enum TabsOrderTracking {
  WILL = 'Will',
  LOCATION = 'Location',
  INFORMATION = 'Information',
  DEATH_PROBATE = 'Death and probate notices',
  ORDER_HISTORY = 'Order History'
}

export enum PersonType {
  WILL = 1,
  EXECUTOR = 2,
  GUARDIAN = 3,
  POWEROFATTORNEY = 4,
  BENEFICIARY = 5,
  CLIENTWITHOUTWILL = 6
}

export enum BusinessOrderSearchType {
  PEOPLE = 1,
  DEATHNOTICE = 2,
  PROBATENOTICE = 3
}

export enum BusinessOrderProductType {
  WILL = 'Will',
  EXECUTOR = 'Executor',
  ENDURINGGUARDIAN = 'Guardian',
  POWEROFATTORNEY = 'PowerOfAttorney',
  BENEFICIARY = 'Beneficiary',
  DEATHNOTICE = 'DeathNotice',
  PROBATENOTICE = 'ProbateNotice'
}

export enum OrderStatus {
  WAITING = 'Waiting',
  COMPLETED = 'Completed',
  ERROR = 'Error'
}

export enum Status {
  CONFIRMED = 1,
  DISMISSED = 2
}

export enum NoticeType {
  ALL,
  PROBATE,
  DEATH
}

export enum SessionStorageKey {
  IS_NOTIFICATION_SETUP = 'isNotificationSetup',
  POSTCODE = 'postcode'
}

export enum LocalStorageKey {
  TOKEN = 'TOKEN'
}

export const TIMING = {
  ONE_MINUTE: 60 * 1000,
  ONE_HOUR: 60 * 60 * 1000
}
