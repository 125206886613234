import { logger } from './datadog'

export const setLocalStorage = (key: string, value: any) => {
  const data = JSON.stringify(value)
  localStorage.setItem(key, data)
}

export const getLocalStorage = (key: string): any => {
  const data = localStorage.getItem(key)
  try {
    return data ? JSON.parse(data) : undefined
  } catch (error) {
    logger.error('PARSE_TOKEN: Error parsing data from local storage', error as any)
    setLocalStorage(key, data)
    return data
  }
}

export const setSessionStorage = (key: string, value: any) => {
  const data = JSON.stringify(value)
  sessionStorage.setItem(key, data)
}

export const getSessionStorage = (key: string): any => {
  const data = sessionStorage.getItem(key)
  return data ? JSON.parse(data) : undefined
}
