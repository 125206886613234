/** @jsxImportSource theme-ui */
import { RouterProvider } from 'react-router-dom'
import { Suspense, useEffect } from 'react'
import Fallback from '@components/Fallback/Fallback'
import { Illustration } from '@infotrack/zenith-ui'
import router from '@routes/routes'
import { initialiseLogger } from '@utils/datadog'
import { getMarkdownEndpoint } from '@apis/markdownText'
import { getToken } from '@utils/authenticate'
import { useMarkdownText } from '@hooks/useMarkdownText'
import { firstTimeLoading } from '@utils/firstTimeLoading'

const App = () => {
  const { init } = useMarkdownText()
  useEffect(() => {
    firstTimeLoading()
    // start up datadog
    initialiseLogger({
      clientToken: process.env.CLIENT_TOKEN_DD || '',
      env: process.env.NODE_ENV || '',
      service: 'willregistry-ui-web',
      site: 'datadoghq.com'
    })

    init({
      token: getToken(),
      url: getMarkdownEndpoint(),
      Illustration: Illustration
    })
  }, [])

  return (
    <Suspense fallback={<Fallback />}>
      <RouterProvider router={router} fallbackElement={<Fallback />} />
    </Suspense>
  )
}
export default App
